<template>
  <div :class="customClass" :id="elementId">
    <div w1200 margin-auto>
      <div flex-row-space-between-center style="position: relative;">
        <cz-home-big-title title="精彩图片"></cz-home-big-title>
        <img src="@/assets/img/home/more-right.png" @click="moreImageClicked" style="height:28px;cursor:pointer;" alt="">
      </div>

      <el-carousel :interval="4000" type="card" height="400px" indicator-position="none">
        <el-carousel-item v-for="(wonderfulPicture,index) in computedRecommendWonderfulPictures " :key="wonderfulPicture.id">
          <!-- <h3 class="medium">{{ item }}</h3>-->
          <div flex-row-center-center style="width: 100%;height: 100%;">
            <!-- <img style="display:block;max-width: 100%;max-height: 100%" :src="wonderfulPicture" alt="">-->
            <!--            <el-image class="image" :src="wonderfulPicture.src" :preview-src-list="computedRecommendWonderfulPictures.map(item=>item.src)" fit="fill"></el-image>-->
            <el-image class="image" :src="normalizeImageUrl(wonderfulPicture.src)" :preview-src-list="computedRecommendWonderfulPictures.map(item=>normalizeImageUrl(item.src))"></el-image>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
// 600/x = 3/2
// import {mapMutations, mapActions, mapState, mapGetters} from 'vuex';
import {isEmpty}       from 'lodash'
import DataConfigMixin from '@/mixins/data-config-mixin'

export default {
  inject: {
    home: { from: 'home' },
  },
  // extends   : {},
  mixins    : [DataConfigMixin],
  components: {},
  name      : 'WonderfulPicturesComponent',
  props     : {
    className: { type: String, default: '' },
  },
  data() {
    return {}
  },
  watch   : {
    // someObject: { handler: function (val, oldVal) { /* ... */ }, deep: true, immediate: true },
  },
  computed: {
    computedRecommendWonderfulPictures() {
      let recommendWonderfulPictures = this.dataConfig.wonderfulPictures.filter(item => {
        return item.recommend
      })
      if (isEmpty(recommendWonderfulPictures)) {
        return []
      }
      else {
        return recommendWonderfulPictures
      }
    },
    elementId() {return `WonderfulPicturesComponent_${this._uid}`},
    customClass() {return ['WonderfulPicturesComponent', this.className]},
  },
  methods : {

    moreImageClicked() {
      this.$router.push({ name: 'ImageCollection' })
    },
  },

  created() {
    // document.documentElement.scrollTop = 0

  },
  destroyed() {},
  mounted() {},
}
</script>

<style scoped lang="scss" rel="stylesheet/scss">
.WonderfulPicturesComponent {

  ::v-deep .el-carousel__item h3 {
    color       : #475669;
    font-size   : 14px;
    opacity     : 0.75;
    line-height : 200px;
    margin      : 0;
  }

  //::v-deep .el-carousel__item:nth-child(2n) {
  //  background-color : #99a9bf;
  //}
  //
  //::v-deep .el-carousel__item:nth-child(2n+1) {
  //  background-color : #d3dce6;
  //}

  .image {
    //display    : block;
    ////width   : 100%;
    //max-width  : 100%;
    //max-height : 100%;
    width           : 100%;
    height          : 100%;

    display         : flex;
    justify-content : center;
    align-items     : center;

    //::v-deep .el-image__inner {
    //  max-width  : 100%;
    //  max-height : 100%;
    //  display    : block;
    //  width      : auto;
    //  height     : auto;
    //}

    ::v-deep .el-image__inner {
      //max-width  : 100%;
      height  : 100%;
      display : block;
      width   : auto;
      //height     : auto;
    }
  }
}
</style>
